import { AppThunk, RootState } from "@/app/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EntityInfo,
  GetEntityInfo,
  GetRegulationList,
  GetWalletsList,
  RegulationInfo,
  WalletsInfo,
  AddressRes,
  GetAddressList,
  GetAddressParams,
} from "./entityApi";

export interface EntitySliceIFace {
  loading: boolean;
  info: EntityInfo | null;
  regulations: Array<RegulationInfo> | null;
  wallets: Array<WalletsInfo> | null;
  addressList: AddressRes | null;

  entityId: number;
}

const initialState: EntitySliceIFace = {
  loading: false,
  info: null,
  regulations: null,
  wallets: null,
  addressList: null,

  entityId: 0,
};

export const init = createAsyncThunk(
  "entity/init",
  async (entityId: number) => {
    const entityInfo = await GetEntityInfo(entityId);
    return entityInfo;
  }
);

export const GetRegulation = createAsyncThunk(
  "entity/regulation",
  async (entityId: number) => {
    const regulation = await GetRegulationList(entityId);
    return regulation;
  }
);

export const GetWallets = createAsyncThunk(
  "entity/wallets",
  async (entityId: number) => {
    const wallets = await GetWalletsList(entityId);
    return wallets;
  }
);

export const GetAddress = createAsyncThunk(
  "entity/address",
  async (params: GetAddressParams) => {
    const wallets = await GetAddressList(params);
    return wallets;
  }
);

export const EntitySlice = createSlice({
  name: "entity",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    reverseWallets: (state) => {
      if (state.wallets) state.wallets = state.wallets.reverse();
    },
    reverseRegulation: (state) => {
      if (state.regulations) state.regulations = state.regulations.reverse();
    },
    setEntityId: (state, action: PayloadAction<number>) => {
      state.entityId = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(init.pending, (state, action) => {
        state.info = null;
        state.regulations = null;
      })
      .addCase(init.fulfilled, (state, action) => {
        state.info = action.payload;
        state.loading = false;
      })
      .addCase(GetRegulation.pending, (state, action) => {
        state.regulations = null;
      })
      .addCase(GetRegulation.fulfilled, (state, action) => {
        state.regulations = action.payload;
      })
      .addCase(GetWallets.pending, (state, action) => {
        state.wallets = [];
      })
      .addCase(GetWallets.fulfilled, (state, action) => {
        state.wallets = action.payload;
      })

      .addCase(GetAddress.pending, (state, action) => {
        state.addressList = null;
      })
      .addCase(GetAddress.fulfilled, (state, action) => {
        state.addressList = action.payload;
      });
  },
});

export const initEntity =
  (id: number): AppThunk =>
  async (dispatch, getState) => {
    const state = getState().entity;
    const loading = state.loading;
    console.log(loading, id, state.entityId);
    if (!loading && id !== state.entityId) {
      dispatch(setLoading(true));
      dispatch(init(id)).then((res) => {
        const r: any = res.payload;
        // console.log(["Exchange", "Gambling"].indexOf(r.type) != -1)
        if (showRegulations(r.type)) {
          dispatch(GetRegulation(id));
        }
      });
      dispatch(GetWallets(id));
      dispatch(setEntityId(id));
    }
  };

export const selectLoading = (state: RootState) => state.entity.loading;
export const selectEntityInfo = (state: RootState) => state.entity.info;
export const selectRegulation = (state: RootState) => state.entity.regulations;
export const selectWallets = (state: RootState) => state.entity.wallets;
export const selectAddress = (state: RootState) => state.entity.addressList;

export const { setLoading, reverseWallets, reverseRegulation, setEntityId } =
  EntitySlice.actions;
export function showRegulations(type: string): boolean {
  return (
    [
      "Exchange",
      "Gambling",
      "ATM",
      "Bank",
      "Dex",
      "NFT Marketplace",
      "Payment Service Provider",
    ].indexOf(type) != -1
  );
}
export function showHackDetail(type: string): boolean {
  return (
    [
      "Exchange",
      "Gambling",
      "ATM",
      "Bot",
      "Dex",
      "Donations",
      "NFT Marketplace",
      "Payment Service Provider",
    ].indexOf(type) != -1
  );
}
export default EntitySlice.reducer;
